import { Flex,ListItem,UnorderedList,Box,Text,Collapse } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { HiOutlineChevronRight,HiOutlineChevronLeft} from 'react-icons/hi'
import { sidebarData } from '../../../routesList'
import { WorkspaceContext } from "../Layout/Layout"
import { useServicesContext } from "services/apiServices"

const Sidebar = ({ appData, handleSidebar, sidebarCollapsed, identity }) => {
    const {disabledSources} = useServicesContext()
    const {active,setActive} =useContext(WorkspaceContext)
    
    const[openItems,setOpenItems]=useState([1,1.1])
    const tenantSetting=appData.global.tenantSettings

    useEffect(()=>{
        const checkAlowedRoutes = (route, ProductSettigs1) => {
            return route.subMenuItems && route.subMenuItems.map(route2 => {
                const label=route2.label.toLowerCase()
                if ( ProductSettigs1.properties[label]?.status === 1) {
                    route2.isNav = true
                    // if (route2.childMenuItems) {
                    //     route2.childMenuItems = checkAlowedRoutes(route2, ProductSettigs1)
                    // }
                } else if (ProductSettigs1.properties[label]?.status === 0) {
                    route2.isNav = false
                }
                else{
                    route2.isNav=true
                }
                return route2
            })
        }
        sidebarData && sidebarData.map(route => {
            const correspondingSetting = tenantSetting.find(item=>item?.properties?.label===route.label)
            if (correspondingSetting && (correspondingSetting?.properties?.status === 1)) {
                route.isNav = true
                if (route.subMenuItems && correspondingSetting ) {
                    route.subMenuItems = checkAlowedRoutes(route, correspondingSetting)
                    return route
                }
            } else if (correspondingSetting && (correspondingSetting?.properties?.status === 0)) {
                route.isNav = false
                return route
            }
            else{
                if (route.subMenuItems && correspondingSetting ) {
                    route.isNav=true
                    route.subMenuItems = checkAlowedRoutes(route, correspondingSetting)
                    return route
                }
            }
        })
    },[tenantSetting])
    let filteredMenu=[]
    sidebarData.filter((role)=>{
            if(role?.allowedRole.some(item=>identity?.roles.includes(item) && role.isNav===true)){
            filteredMenu.push({...role,subMenuItems:[]})
            }
            if(role.subMenuItems){
                const filteredSubMenuItems= role.subMenuItems.filter(submenuRole=>{
                    if(submenuRole.allowedRole.some(subItem=>identity?.roles.includes(subItem) && submenuRole.isNav===true)){
                        return submenuRole
                    }
                })
                filteredMenu.map(item=>{
                    return item.subMenuItems=filteredSubMenuItems
                })
            } 
    })
    const onChildToggle=(e,id)=>{
        e.preventDefault()
        e.stopPropagation()
        if(openItems.includes(id)){
            setOpenItems(openItems.filter(item=>item!==id))
        }
        else{
            setOpenItems([...openItems,id])
        }   
    }
    const handleMenuLink=(link,e)=>{
        e.preventDefault()
        e.stopPropagation()
            let url=window.location.href
        let replaceUrl = url.substring(0, url.lastIndexOf('.ai') +3)
        let newUrl=replaceUrl.concat(link)
        if(newUrl !== window.location.href ) {window.location.assign(newUrl)}
    }
    const handleChildMenu=(ele,e)=>{
        e.stopPropagation()
        const knowledgeSources=sidebarData[0].subMenuItems.filter(item=>item?.id===1.1)
        if(knowledgeSources[0].childMenuItems.find(item=>item.id===ele.id)){
            setActive(ele.id)
        }
    }
    useEffect(()=>{
        let knowledgeSources=sidebarData[0].subMenuItems.filter(item=>item?.id===1.1)
        knowledgeSources[0].childMenuItems.find(item=>{
            if(item.path===window.location.pathname){ 
               setActive(item.id)
            }
        })
    },[])
    return (
        <Flex bg="#fff" transition='all 0.3s ease-in-out' w={sidebarCollapsed?"60px":"243px"} minW={sidebarCollapsed?"60px":"243px"} position='relative' className={sidebarCollapsed?"sidebar closed":"sidebar"} height={'calc(100vh - 45px)'}  zIndex={99} >
        <Box zIndex='99' w='23px' h='23px' position='absolute' right='-12px' top='5px' cursor='pointer' onClick={handleSidebar} background='#fff' boxShadow='0 1px 10px #ccc' borderRadius='50%' border='1px solid #ccc' display='flex' justifyContent='center' alignItems='center' >{sidebarCollapsed?<HiOutlineChevronRight size='16px' color='#1e90ff' />:<HiOutlineChevronLeft size='16px' color='1e90ff' />}</Box>
        <UnorderedList className="sidebar-menu-list" listStyleType='none' margin='0' color='rgb(108, 114, 128)' w='100%' pt='10px' overflowY='auto' overflowX='hidden'>
        {
            filteredMenu.map((item,index)=>{
                return(
                   <ListItem data-testid={item.label} className={openItems.includes(item.id)?"menu-item nav-item nav-dropdown dropdown-toggle":"menu-item nav-item nav-dropdown"} onClick={(e)=>{onChildToggle(e,item.id)}} key={index} >
                        <Box w={sidebarCollapsed?"60px":"243px"} ps='12px' display='flex' justifyContent='space-between' alignItems='center' className={item.subMenuItems.length>0?'menu submenu':'menu'} >
                               {
                                item.subMenuItems && item.subMenuItems.length>0 ? (
                                    <Box display='flex' alignItems='center' h='45px' w='100%'  >
                                        <Box className={(item?.isActive || (sidebarCollapsed && item.id===1))?'menu-link nav-link active':'menu-link'} >
                                            <Text as='span' className="menu-icon" marginRight='6px' width='30px' height='30px' display='flex' justifyContent='end' alignItems='end'>
                                            {typeof item?.icon === 'object' ? item.icon : <i className={item.icon}></i>}
                                            </Text>
                                        </Box>
                                        <span style={sidebarCollapsed?({display:'none'}):({display:'block'})} ><Text as='span' fontSize='13px' fontWeight='500' className='label text-span'>{item.name}</Text>{item.badge}</span>  
                                     </Box>
                                ):(
                                   <Box onClick={(e)=>{handleMenuLink(item?.path,e)}} h='45px' w='100%' >
                                        <Box display='flex' alignItems='center'  >
                                            <Box className={(item?.isActive || (sidebarCollapsed && item.id===1))?'menu-link nav-link active':'menu-link'} >
                                                <Text as='span' className="menu-icon" marginRight='6px'  width='30px' height='30px' display='flex' justifyContent='end' alignItems='end'>
                                                {typeof item?.icon === 'object' ? item.icon : <i className={item.icon}></i>}
                                                </Text>
                                            </Box>
                                            <span style={sidebarCollapsed?({display:'none'}):({display:'block'})} ><Text as='span' fontSize='13px' fontWeight='500' className='label text-span'>{item.name}</Text>{item.badge}</span>  
                                        </Box>
                                   </Box>
                                )
                               }
                        </Box>
                        <Collapse in={openItems.includes(item.id)} animateOpacity >
                        <UnorderedList display={sidebarCollapsed?"none":"block"} transition="all .3s ease-in-out"  listStyleType='none' m='0'>
                        {
                            item?.subMenuItems && item?.subMenuItems.filter((data)=>{
                                return data?.allowedRole.some(item=>identity?.roles.includes(item))
                            }).map((element,ind)=>{
                                return(
                                  <ListItem data-testid={element.label} key={ind} paddingY='8px' ps='36px' m='0' className={openItems.includes(element.id)?"submenu-item nav-item nav-dropdown submenu-dropdown-toggle":"submenu-item nav-item nav-dropdown"}  >
                                     <Box className={element?.childMenuItems?'menu childmenu':'menu'} onClick={(e)=>{onChildToggle(e,element?.id)}} >
                                           {
                                            element.childMenuItems?(
                                                <Box display='flex' alignItems='center' pe='12px' maxH='29px' className={item?.isActive?'sub-menu-link nav-link active':'sub-menu-link nav-link'} >
                                                    <Text as='span' className="sub-menu-icon" w='30px' h='30px' marginRight='6px' display='flex' justifyContent='center' alignItems='center'>
                                                    {typeof element?.icon === 'object' ? element.icon : <i className={element.icon}></i> }
                                                    </Text>
                                                    <span style={sidebarCollapsed?({display:'none'}):({display:'block'})} ><Text as='span' fontSize='13px' fontWeight='500' className='label text-span'>{element.label}</Text>{element.badge}</span>
                                                </Box>
                                            ):(
                                                <Box onClick={(e)=>{handleMenuLink(element?.path,e)}} pe='12px' width='100%' maxH='29px' >
                                                    <Box display='flex' alignItems='center' className={item?.isActive?'sub-menu-link nav-link active':'sub-menu-link nav-link'} >
                                                        <Text as='span' className="sub-menu-icon" w='30px' h='30px' marginRight='6px' display='flex' justifyContent='center' alignItems='center'>
                                                        {typeof element?.icon === 'object' ? element.icon : <i className={element.icon}></i> }
                                                        </Text>
                                                        <span style={sidebarCollapsed?({display:'none'}):({display:'block'})} ><Text as='span' fontSize='13px' fontWeight='500' className='label text-span'>{element.label}</Text>{element.badge}</span>
                                                    </Box>
                                                 </Box>
                                            )
                                           }
                                     </Box>
                                     <Collapse in={openItems.includes(element.id)} animateOpacity>
                                     <UnorderedList listStyleType='none' m='0' ps='8px' pt='10px' className='child-menu-list' >
                                     {
                                        element?.childMenuItems && element?.childMenuItems.filter((data) => { 
                                            return data?.allowedRole.some(item=>identity?.roles.includes(item)) && (data.path.startsWith('/aiconsole')? !disabledSources.includes(data.name) : true)
                                        }).map((ele,i)=>{
                                            return(
                                            <ListItem data-testid={ele.label} key={i} m='0' className="childmenu-item nav-item nav-dropdown" >
                                                <Box pe='6px' >
                                                {
                                                    ele?.path.startsWith('/aiconsole')?
                                                    <NavLink onClick={(e)=>{handleChildMenu(ele,e)}} to={ele.path} style={sidebarCollapsed?({display:'none',paddingInlineStart:'14px',paddingBlock:'7.5px'}):({display:'block',paddingInlineStart:'14px',paddingBlock:'7.5px'})} width='100%'>
                                                        <Box display='flex' paddingY='3px' ps='11px' alignItems='center' className={active===ele.id?'nav-link active':'nav-link'} >
                                                        <Text as='span' fontSize='13px' fontWeight='500' className='label text-span' ><Text as='span' >{ele.label}</Text></Text>
                                                        </Box>
                                                    </NavLink>
                                                    :
                                                    <Box ps='14px' py='7.5px' onClick={(e)=>{handleMenuLink(ele?.path,e)}} width='100%'>
                                                        <Box display='flex' paddingY='3px' ps='11px' alignItems='center' className={active===ele.id?'nav-link active':'nav-link'} >
                                                             <span style={sidebarCollapsed?({display:'none'}):({display:'block'})} ><Text as='span' fontSize='13px' fontWeight='500' className='label text-span'>{ele.label}</Text>{ele.badge}</span>
                                                        </Box>
                                                    </Box>
                                                }
                                                </Box>
                                                <UnorderedList>
                                                </UnorderedList>
                                            </ListItem>
                                            )
                                        })
                                    }
                                     </UnorderedList>
                                     </Collapse>
                                  </ListItem>
                                )
                            })
                           }
                        </UnorderedList>
                        </Collapse>
                   </ListItem>
                )
            })
        }
        </UnorderedList>
        </Flex>
    )
}

export default Sidebar
