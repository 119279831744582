import { useQuery, useQueryClient, useMutation } from 'react-query'
import { ServiceHelper } from '../../helpers/ServiceHelper'

const useEntity = ({ reqPath }) => {
  return useQuery(
    ['UserSetting', reqPath],
    () => {
      return ServiceHelper.prepAndCall({ method: 'GET', reqPath: reqPath }).then(res => res.data)
    },
    { refetchOnWindowFocus: false },
  )
}

const updateUserSetting = ({ payload, reqPath, method}) => {
  let requestOptions = ServiceHelper.prepareConfig({
    method: method || 'POST',
    reqPath: reqPath ? reqPath : '/UserSetting/upsert',
    data: payload,
  })
  return ServiceHelper.makeAsyncCall(requestOptions).then(res => res.data)
}

const useUpdateUserSetting = () => {
  const queryClient = useQueryClient()
  return useMutation(updateUserSetting, {
    onError: (err, newTodo, context) => {
      queryClient.setQueryData('UserSetting', context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries('UserSetting')
    },
  })
}

const deleteUserSetting = ({ id, reqPath }) => {
  let requestOptions = ServiceHelper.prepareConfig({
    method: 'DELETE',
    reqPath: reqPath? `${reqPath}/${id}`: '/UserSetting/'+id,
  })
  return ServiceHelper.makeAsyncCall(requestOptions).then(res => res.data)
}

const useDeleteUserSetting = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteUserSetting, {
    onError: (err, newTodo, context) => {
      queryClient.setQueryData('UserSetting', context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries('UserSetting')
    },
  })
}

export { useEntity, useUpdateUserSetting, useDeleteUserSetting }
