import { Box,Text, UnorderedList, ListItem,IconButton} from "@chakra-ui/react"
import {GrFormPrevious,GrFormNext} from 'react-icons/gr'
const Pagination = ({ totalPages, currentPage, onPageChange }) => {

    const handlePageClick = (page) => {
      onPageChange(page)
    }
  
    const renderPaginationButtons = () => {
      const visiblePages = 3 
      const halfVisible = Math.floor(visiblePages / 2)

      const startPage = currentPage===totalPages? Math.max(1,currentPage-visiblePages+1) : Math.max(1, currentPage - halfVisible)
      const endPage = Math.min(totalPages, startPage + visiblePages - 1)
  
      return (
        <>
          {
            startPage-1>=1 && startPage < currentPage &&  
              (
                <>
                  <ListItem  px='1' cursor='pointer' onClick={()=>{handlePageClick(1)}} >
                    <Text as='span' padding='5px 8px' borderRadius='5px' border='1px solid #e2e8f0' backgroundColor="transparent" color="#000">1</Text>
                  </ListItem>
                  <ListItem  px='1' >
                    <Text as='span' padding='5px 8px' borderRadius='5px' border='1px solid #e2e8f0' backgroundColor="transparent" color="#000">...</Text>
                  </ListItem>
                </>
              )
          }
          {
            Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index)
            .map((page) =>{
              return (
                <ListItem key={page} px='1' cursor='pointer' onClick={()=>{handlePageClick(page)}} >
                  <Text as='span' padding='5px 8px' borderRadius='5px' border='1px solid #e2e8f0' backgroundColor={currentPage===page?"#1e90ff":"transparent"} color={currentPage===page?"#fff":"#000"} >{page}</Text>
                </ListItem>
              )
            })
          }
          {
            endPage+1<=totalPages && currentPage < endPage &&
              (
                <>
                  <ListItem px='1' >
                    <Text as='span' padding='5px 8px' borderRadius='5px' border='1px solid #e2e8f0' backgroundColor="transparent" color="#000">...</Text>
                  </ListItem>
                  <ListItem  px='1' cursor='pointer' onClick={()=>{handlePageClick(totalPages)}} >
                    <Text as='span' padding='5px 8px' borderRadius='5px' border='1px solid #e2e8f0' backgroundColor="transparent" color="#000">{totalPages}</Text>
                  </ListItem>
                </>
              )
          }
        </>
      )
    }
  
    return (
        <Box display='flex' gap='2' alignItems='center' >
            <Box cursor='pointer' ><IconButton variant="ghost" colorScheme="white" disabled={currentPage === 1} onClick={() => handlePageClick(Math.max(1, currentPage - 1))} icon={<GrFormPrevious />}></IconButton></Box>
            <UnorderedList listStyleType={'none'} display='flex' m='0' >
              {renderPaginationButtons()}
            </UnorderedList>
            <Box cursor='pointer' > <IconButton variant="ghost" colorScheme="white" onClick={() => handlePageClick(Math.min(totalPages, currentPage + 1))} disabled={currentPage === totalPages} ><GrFormNext /></IconButton></Box>
        </Box>
    )
  }

  export default Pagination