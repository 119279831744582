import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Flex,
    Heading,
    useColorMode,
    useColorModeValue,
    useMediaQuery
} from '@chakra-ui/react'
import {
    ThemeContext,
} from './common/ui'
import { withTranslation } from 'react-i18next'
import Sidebar    from './common/components/sidebar'
import { RootRouter } from './common/router'
import { userSettingHooks } from "./common"
import AppContext from "AppContext"
import Home from 'app/Home'
import Layout from 'app/commonComponents/Layout/Layout'
import ContentManagement from 'app/content/pages/ContentManagement'
import {CommonContextProvider} from 'app/context/CommonContext'

const {useEntity}  = userSettingHooks
const MainLayout = (props) => {
    const { config, lightTheme, darkTheme, routes, identity,t,logout } = props
    const [isMobile] = useMediaQuery("(max-width: 768px)")
    const { colorMode, toggleColorMode: toggleDarkMode } = useColorMode()
    const theme = useColorModeValue(lightTheme, darkTheme)
    const isDarkMode = useMemo(() => (colorMode === 'dark'), [colorMode])
    const [isThemeUpdated, setIsThemeUpdated] = useState(false)
    const renderComponent = useCallback(({ Component, title }) => (
        // <Flex direction="column" bg={theme.bg1} w="100%">
        <Flex direction="column" w="100%">
            {/* <Flex gridColumnGap={2}>
                <Heading size="lg" color={theme?.headingColor || theme.fg1}>{title}</Heading>
            </Flex> */}
            <Component />
        </Flex>
    ), [theme])

    const userId = identity?.profile?.userId
    const {data: userSetting, isFetched: isFetchUserSetting}  = useEntity({reqPath: `UserSetting?$filter=userId eq '${userId}'`})
    useEffect(()=>{
        if(!isThemeUpdated && isFetchUserSetting){
        let userSettingData = userSetting && userSetting.find(item=>item.entity==="theme")
        if(userSettingData && userSettingData.activeTheme==="Dark" && !isDarkMode)
         toggleDarkMode()
        else if(userSettingData && userSettingData.activeTheme==="Light" && isDarkMode)
         toggleDarkMode()
         setIsThemeUpdated(true)
        }
    }, [isFetchUserSetting, toggleDarkMode, isThemeUpdated, userSetting, isDarkMode])

    return (
        <ThemeContext.Provider value={{ isDarkMode, theme, toggleDarkMode }}>
        <Flex h="100vh">
       <Layout logout={logout}>
       <RootRouter config={config} routes={routes} render={renderComponent} />
       </Layout>
        </Flex>
        </ThemeContext.Provider>
    )
}

export default withTranslation()(MainLayout) 