
import {useEffect} from 'react'
import {Box,Text, Flex,Tooltip,IconButton,Spinner} from "@chakra-ui/react"
import { useQuery,useQueryClient } from 'react-query'
import {MdMenu} from 'react-icons/md'
import {FiEdit} from 'react-icons/fi'
import {HiOutlineTrash} from 'react-icons/hi2'
import {HiOutlineArrowCircleLeft} from 'react-icons/hi'
import {MdOutlineRemoveRedEye,MdOutlineStarBorder} from 'react-icons/md'
import parse from 'html-react-parser'
import {useKnowledgeArticlesContext} from './context/KnowledgeArticlesContext'

const KnowledgeArticlesDetailPage = () => {
    const {globalState,openListPage,openEditPage,openDeleteModal,updateCreateEditPageProps,updateDetailPageProps,apiCall,updateArticle} = useKnowledgeArticlesContext()
    const queryClient = useQueryClient()
    const commonProps = {borderRadius:'2px', px:'5px', py:'2px',fontSize:'11px'}
    const purpleBadgeProps={...commonProps, color:'#8463F9', bg:'#EDE8FE', border:'1px solid #8463F9'}

    const getArticles = () => apiCall({
        options: {
            url: `InputFiles/getFiles`,
            method: "GET",
            query:`$filter=id eq ${globalState.selectedArticle}`
            },
    })
  
    const { isFetching, data,} = useQuery(['article'], ()=>getArticles(),{enabled:globalState.detailPageProps.fetchArticle})
    const article = data? data.data.rows[0].articleData: globalState.articles.find(item=> item.id === globalState.selectedArticle)?.articleData
   
    useEffect(()=>{
        if(data){
            updateDetailPageProps({fetchArticle:false})
            updateArticle(data.data.rows[0])
        }
    },[data])

    const handleEdit = () =>{
        updateCreateEditPageProps('detail')
        openEditPage()
    }

    const handleBack = () => {
        queryClient.removeQueries('article')
        openListPage()
    }
   
    return (
        <>
          {
            isFetching ?
              ( 
                <Flex direction="column" justifyContent='center' alignItems='center' >
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#2ac769'
                        size='xl'
                        data-testid='ka_table_fetching_articles_spinner'
                    />
                    <Text my='2' data-testid='ka_detail_fetching_article_text'>Fetching Article ...</Text>
                </Flex>
              ):
            <Flex direction='column' gap='10px' padding='0px 6px' justify='center' alignItems='center' data_testid='ka_detail_container'>
                <Flex justify='flex-start' padding='10px 24px 13px 24px' borderBottom='1px solid #D1D5DB' alignItems='flex-start' alignSelf='stretch' gap='10px' data_testid='ka_detail_header'>
                    <HiOutlineArrowCircleLeft cursor='pointer' size='26px' color='#94A3B8' onClick={handleBack} data-testid='ka_detail_back_icon'/>
                    <Flex direction='column' gap='10px'>
                        <Text as='span' fontSize="14px" fontWeight='500' color='#000' data-testid='ka_detail_header_title'>{article.title}</Text>
                        {/* -------------------- Disable Breadcrumbs until there is a way to obtain breadcrumb data -----------
                        <Flex alignItems='center' gap='10px'>
                            <MdMenu size='17px' color='#94A3B8' data-testid='ka_detail_breadcrumbs_icon'/>
                            <Text fontSize='14px' color='6B7280' data-testid='ka_detail_breadcrumbs'>{`Knowledge Sources > Knowledge Articles`}</Text>
                        </Flex> */}
                    </Flex>
                </Flex>
                <Flex direction='column' padding='0px 25px 18px 25px' alignItems='flex-start' alignSelf='stretch' data_testid='ka_detail_body'>
                    <Flex direction='column' alignItems='flex-start' padding='21px 0px' borderBottom='1px solid #D1D5DB' gap='15px' alignSelf='stretch'>
                        <Flex gap='5px' alignItems='center'>
                            <Text as='span' fontSize="20px" fontWeight='600' color='#000' data-testid='ka_detail_body_title'>{article.title}</Text>
                            {article.scripted &&
                                <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #8463F9'>
                                    <Text as='span' fontSize="12px" fontWeight='bolder' color='#8463F9' data_testid='ka_detail_scripted'>Scripted Answer</Text>
                                </Flex>
                            }
                            {article.resolution_note &&
                                <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #229F54'>
                                    <Text as='span' fontSize="12px" fontWeight='bolder' color='#229F54' data_testid='ka_detail_scripted'>Resolution Note</Text>
                                </Flex>
                            }
                            {article.restrictAgents &&
                                <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #E79B04'>
                                    <Text as='span' fontSize="12px" fontWeight='bolder' color='#E79B04' data_testid='ka_detail_scripted'>Agent note</Text>
                                </Flex>
                            }
                        </Flex>  
                        <Flex justify='flex-end' h='24px' alignItems='center' alignSelf='stretch'>
                            {/* --------------------- Disable for Nov 2023 release --------------------
                            <Flex padding='2px 5px' h='19px' gap='4px' alignItems='center' borderRadius='2px' border='1px solid #2563EB'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path d="M1.25 4.56246V11.4375H6.19625L9.63375 7.99996L6.19625 4.56246H1.25ZM2.5 5.81246H5.67875L7.86625 7.99996L5.67875 10.1875H2.5V5.81246ZM9.11625 5.18746L11.9287 7.99996L9.11625 10.8125L10 11.6962L13.6962 7.99996L10 4.30371L9.11625 5.18746Z" fill="#2563EB"/>
                                </svg>
                                <Text as='span' fontSize='12px' fontWeight='bolder' color='#2563EB' data_testid='ka_detail_folder'>{article.folder}</Text>
                            </Flex> */}
                            <Flex>
                                <Tooltip placement='top' label='Edit Article' data_testid='ka_detail_edit_tooltip'><IconButton aria-label="Edit Article" data_testid='ka_detail_edit_button' variant='ghost' color='#94A3B8' onClick={()=>handleEdit()} icon={<FiEdit />} fontSize='24px'></IconButton></Tooltip>
                                <Tooltip placement='top' label='Delete Article' data_testid='ka_detail_delete_tooltip'><IconButton aria-label="Delete Article" data_testid='ka_detail_delete_button' variant='ghost' color='#94A3B8' onClick={()=>openDeleteModal(globalState.selectedArticle,article.id,article.status[0].label!=='Draft')} icon={<HiOutlineTrash/>} fontSize='24px'></IconButton></Tooltip>
                            </Flex>
                        </Flex>
                    </Flex>  
                    <Box className='ql-container'>
                        <Box padding='30px 0px' className='ql-editor ql-snow' data_testid='ka_detail_content'>
                            {parse(article.content)}
                        </Box>
                    </Box>   
                    <Flex justify='space-between' alignSelf='stretch'>
                        {article.audience?.length>0 && 
                            <Flex gap='10px' alignItems='center'>
                                <Text as='span' w='83px' fontSize='14px' color='#000' data_testid='ka_detail_audience_label'>Audience</Text>
                                {article.audience?.map((attribute,index)=>
                                    <Text key={index} as='span' {...purpleBadgeProps} data-testid={`ka_detail_audience_value_${index}`}>{attribute.key}: {attribute.values.join(', ')}</Text>
                                )}
                            </Flex>
                        }
                         <Flex gap='10px'>
                            {(article.views !==null &&  article.views !== undefined && article.views !==0) &&
                                <Tooltip placement='bottom-start' data-testid='ka_detail_view_tooltip' label={`was referred ${article.views} times in bot conversations`} fontSize='12px' bg='#fff' color='#000' borderRadius='5px' boxShadow='0px 4px 15px 0px rgba(0, 0, 0, 0.10)'>
                                    <Flex padding='2px 5px' h='19px' gap='4px' borderRadius='2px' border='1px solid #2563EB' color='#2563EB' alignItems='center'>
                                            <MdOutlineRemoveRedEye size='15px' data-testid='ka_detail_view_icon'/>
                                            <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_detail_view_data'>{article.views}</Text>
                                    </Flex>
                                </Tooltip>
                            }  
                            {(article.rating !==null &&  article.rating !== undefined && article.rating !==0) &&
                                <Flex padding='2px 10px' h='19px' gap='5px' borderRadius='2px' border='1px solid #E79B04' color='#E79B04' alignItems='center'>
                                    <MdOutlineStarBorder size='12px' data-testid='ka_detail_rating_icon'/>
                                    <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_detail_rating_data'>{article.rating}/5</Text>
                                </Flex>  
                            }         
                        </Flex> 
                    </Flex>           
                </Flex>
            </Flex>
          } 
        </>

    )
}

export default KnowledgeArticlesDetailPage