import ConfigType from 'common/ConfigType'

// const hostUrl = process.env.REACT_APP_UI_URL || 'http://sometenant.test.virtualpeople.local:3000'
// const idpUrl = process.env.REACT_APP_IDP_URL || 'https://federation-sts-test.apps.actionable-science.com/auth'

const defaultSubdomains = process.env.REACT_APP_DEFAULT_SUBDOMAIN || 'www,register'
let envs = ['dev', 'test', 'staging', 'stage', 'alpha', 'beta','91social-dev']
const env = envs.some(e => window.location.hostname.includes(e)) && window.location.hostname.split('.')[1]
const rootDomains = process.env.REACT_APP_ROOT_DOMAIN || env ? `${env}.virtualpeople.ai,${env}.rezolve.ai` : `virtualpeople.ai,rezolve.ai`
// const apiHostUrl = process.env.REACT_APP_API_HOST || 'http://localhost:9020'
// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '/api/v1'
// const apiUrl = `${apiHostUrl}${apiBaseUrl}`
const botId = process.env.REACT_APP_BOT_ID
const publicPageUsers = process.env.REACT_APP_PUBLICPAGE_USERS || 'patelco,nexera,patelconewtest,ms'
const localTest = process.env.REACT_APP_LOCAL === 'true' || false
const defaultTenantId = process.env.REACT_APP_DEFAULTTENANTID
const pageLimit = process.env.REACT_APP_PAGELIMIT
const appRootPath = process.env.REACT_APP_ROOTPATH ?? 'agent'
const localeUrl = process.env.REACT_APP_LOCALEURL
// const socketUrl = process.env.REACT_APP_API_HOST
const apiBaseUrlPath = ''
const appName = process.env.REACT_APP_APPROOTPATH || 'agent'
const TARGET_TENANT_DOMAIN = process.env.REACT_APP_TARGET_TENANT_DOMAIN || 'asc-ticket.virtualpeople.ai'
// const cdnUri = process.env.REACT_APP_CDN_URI || 'https://nonprodcdn.blob.core.windows.net'
const headerParams = process.env.headerParams || null
const i18nSaving = process.env.REACT_APP_I18N_SAVING_MISSIG === 'true' || false
const landingPage = process.env.REACT_APP_LANDINGPAGE
const realmName = process.env.REACT_APP_REALNAME
const singleTenant = process.env.REACT_APP_SINGLETENANT
const enableBotStudio = process.env.REACT_APP_ENABLE_BOT_STUDIO === 'true' || false
const enableQnaStudio = process.env.REACT_APP_ENABLE_QNA_STUDIO === 'true' || false
const disableReduxLogger = process.env.REACT_APP_DISABLE_REDUX_LOGGER === 'true' || false
const disableTranslation = process.env.REACT_APP_DISABLE_TRANSLATION === 'true' || false
const defaultPageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE || 100
const minimizedLogo = process.env.REACT_APP_MINIMIZED_LOGO || 'https://as-cdn.azureedge.net/cdn/Rezolve-Monogram-Light.png'
const logo = process.env.REACT_APP_LOGO || 'https://as-cdn.azureedge.net/cdn/logo.png'

function addEnv1(str: any, rootDomain?: any) {
    if (!str) return
    if (str && !str.includes('://')) str = 'https://' + str
    let str1 = 'https://' + str.split('://')[1].split('/')[0]
    let path = str.split('://')[1].split('/')[1] ? '/' + str.split('://')[1].split('/').slice(1).join('/') : ''
    let retStr = str
    if (str1 && !str1.includes('localhost')) {
        envs.some(e1 => {
            let e = `.${e1}.`
            if (window.location.hostname.includes(e) && !str1.includes(e)) {
                if (!str1.includes('.')) {
                    // append entire root here.
                    retStr = `${str1}-${e.slice(1)}${rootDomain}${path || ''}`
                } else {
                    // insert e after prefix domain...
                    let x = str1.split('.')
                    x.splice(1, 0, e.slice(1, -1))
                    retStr = x.join('.') + (path || '')
                }
                if (e.includes('staging')) {
                    retStr = retStr.replace('staging', 'uat')
                }
                if (e.includes('stage')) {
                    retStr = retStr.replace('stage', 'preprod')
                }
                return retStr
            }
        })
        return retStr
    } else return str
}
let hn = process.env.REACT_APP_GLOBALTenant_SERVICE_URL
// let hn
// if (gTSUrl) {
// 	if (gTSUrl.includes('://')) hn = gTSUrl.split('://')[1].split('/')[0]
// 	else hn = gTSUrl.split('/')[0]
// 	if (hn.split('.').length == 1) hn = hn + '.' + window.location.hostname.split('/')[0].split('.').slice(1).join('.')
// 	if (hn.includes(':')) hn = gTSUrl
// }
let globalTSUrl = addEnv1(hn, 'apps.actionable-science.com')
let cdn = process.env.REACT_APP_BOT_CDN || process.env.REACT_APP_CDN_URI
let cdnU = cdn || 'https://nonprodcdn.blob.core.windows.net'
let globalTenantServiceUrl = (globalTSUrl || process.env.REACT_APP_API_HOST) + (hn && !hn.includes('/') ? process.env.REACT_APP_API_BASE_URL || '/api/v1' : '')

const config: ConfigType = {
    // hostUrl,
    rootDomains,
    // idpUrl,
    defaultSubdomains,
    // rootDomain,
    // apiHostUrl,
    // apiBaseUrl,
    // apiUrl,
    botId,
    publicPageUsers,
    localTest,
    defaultTenantId,
    pageLimit,
    appRootPath,
    localeUrl,
    // socketUrl :,
    apiBaseUrlPath,
    appName,
    TARGET_TENANT_DOMAIN,
    cdnUri: cdnU,
    headerParams,
    i18nSaving,
    landingPage,
    realmName,
    singleTenant,
    enableBotStudio,
    enableQnaStudio,
    disableReduxLogger,
    disableTranslation,
    defaultPageSize,
    debugIdentityService: false,
    globalTenantServiceUrl,
    minimizedLogo,
    logo
}

export default config
