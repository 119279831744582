export function isValidImageUrl(url) {
    let  regexhttps = /^https:\/\//i
    if ( regexhttps.test(url) ) { 
        const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg|bmp|svg|ico|webp|tif|tiff)/gi
        return url.match(regex) 
    } else {
        return false
    }
}
export function isValidVideoUrl(url) {
    let  regexhttps = /^https:\/\//i
    if ( regexhttps.test(url) ) {
        const regex = /^(https?\:\/\/)?(www\.)?.+\.(mp4|avi|mov|wmv|flv|mpg|mpeg|mkv|webm)$/
        return regex.test(url) 
    }  else {
        return false
    }
}