import React from 'react'
import {
    Text,
} from '@chakra-ui/react'

const Subheading = ({ title }) => {
    return (
        <Text pl={2} pt={2} textTransform="uppercase" fontSize="xs" fontWeight="bold">
            {title}
        </Text>
    )
}

export default Subheading