import {
    TableContainer,
    Table,
    Thead,
    Th,
    Tr,
    Td,
    Tbody,
    Flex,
    HStack,
    Checkbox,Image,Text,Box,Spinner,
    IconButton,
    Tooltip
  } from "@chakra-ui/react"
  import React,{useState,useContext} from "react"
  import norecord from '../../../assets/norecord.png'
  import {MdOutlineModeEditOutline, MdOutlineDeleteOutline,MdOutlineRemoveRedEye} from 'react-icons/md'
  import {WorkspaceContext} from '../../commonComponents/Layout/Layout'
  import Pagination from "app/commonComponents/Pagination"
  import { useCrawlContext } from "./context/CrawlContext"
 
  const commonProps = {borderRadius:'2px', px:'5px', py:'2px',fontSize:'11px'}
  const orangeBadgeProps={...commonProps, color:'#E79B04', bg:'#F7E9CE', border:'1px solid #E79B04'}
  const greenBadgeProps={...commonProps, color:'#2AC769', bg:'#DFF7E9', border:'1px solid #2AC769'}
  const lastUpdateProps={color:'#E79B04', bg:'#F7E9CE', border:'1px solid #E79B04',borderRadius:'100px', px:'12px', py:'2px',fontSize:'11px', height:'26px'}

  const CrawlTable = ({searchByName}) => {

    const{globalCrawlState,handleCheckboxChange,handleCheckAllUrls,updateCurrentPage,updateItemsPerPage,handleEditUrl,handlePreviewUrl,handleDeleteUrl} = useCrawlContext()
    const records= globalCrawlState.urls
    const [isCheckAll, setIsCheckAll] = useState(false)
    const { workspaceMetadata } = useContext(WorkspaceContext) 
    const pages = []
    const recordswithWorkSpace=records.filter((element)=>{
      if(workspaceMetadata==='noWorkSpace') return element.workspace===null
      else return element.workspace===workspaceMetadata
    })
    const recordsToBeDisplayed = recordswithWorkSpace.filter((data)=>{
     return ((data.crawlStatus==='Published'||data.crawlStatus==='Unpublished')&& ((searchByName==="")||(data.docName.toLowerCase().includes(searchByName.toLowerCase()))))
      
    })
    const pagesLength=Math.max(1,Math.ceil(globalCrawlState.totalRecords / globalCrawlState.itemsPerPage))
    for (let i = 1; i <=pagesLength ; i++) {
    pages.push(i)
    }
    return (
      <React.Fragment>
        <TableContainer >
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    isChecked={(isCheckAll && records.length===0) || (recordsToBeDisplayed.length > 0 && recordsToBeDisplayed.every(record=>globalCrawlState.checkedUrls.some(file=>record.id===file)))}
                    onChange={(e)=> {
                      setIsCheckAll(!isCheckAll)
                      handleCheckAllUrls(e)
                      }}
                  />
                </Th>
                <Th>URL</Th>
                <Th>Owner</Th>
                <Th>Created At</Th>
                <Th>Last Update</Th>
                <Th>Description</Th>
                <Th textAlign='center'>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                globalCrawlState.isFetching ?
                (
                  <Tr>
                    <Td colSpan={9}>
                        <Flex flexDir="column" justifyContent='center' alignItems='center' >
                          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='#2ac769' size='xl' />
                          <Text my='2' data-testid='fetching_urls'>Fetching urls ...</Text>
                        </Flex>
                      </Td>
                  </Tr>
                )
               : recordsToBeDisplayed.length!==0?
               recordsToBeDisplayed.map((e)=>{
                const bagdeProps = e.crawlStatus==='Published'? greenBadgeProps:orangeBadgeProps
                return(
                    <Tr key={e.id}>
                    <Td> 
                      <Checkbox data-testid={`checkAll_url_${e.id}`} value={e.id}
                      isChecked={globalCrawlState.checkedUrls.includes(e.id)}
                      onChange={handleCheckboxChange} />
                    </Td>
                    <Td maxWidth='200px' textOverflow='ellipsis' overflow='hidden' title={e.docName} >{e.docName}</Td>
                    <Td>{e.createdBy}</Td>
                    <Td>{e.createdAt}</Td>
                    <Td>{e.updatedAt}</Td>
                    <Td textOverflow='ellipsis' overflow='hidden' maxWidth='500px' title={e.description}>{e.description?e.description:'-'}</Td>
                    <Td textAlign='center'>
                      <HStack justify='center'>
                        <Tooltip placement='top' label='Edit Url'><IconButton aria-label="Edit URL" variant='ghost' color='#748392'  data-testid='edit_url' onClick={()=>handleEditUrl(e.id)} icon={<MdOutlineModeEditOutline/>} fontSize='21px'></IconButton></Tooltip>
                        <Tooltip placement='top' label='Delete Url'><IconButton aria-label="Delete URL" variant='ghost' color='#748392' data-testid='delete_base_url' onClick={()=>handleDeleteUrl(e.id)} icon={<MdOutlineDeleteOutline/>} fontSize='21px'></IconButton></Tooltip>
                        <Tooltip placement='top' label='Preview Url'><IconButton aria-label="Preview URL" variant='ghost' color='#748392' data-testid='preview_url' onClick={()=>handlePreviewUrl(e.id)} icon={<MdOutlineRemoveRedEye/>} fontSize='21px'></IconButton></Tooltip>
                      </HStack>
                    </Td>
                    </Tr>
                )
                })
                :
                  <Tr>
                    <Td colSpan={9}>
                      <Flex flexDir="column" justifyContent='center' alignItems='center' >
                        <Image width='140px' height='140px' src={norecord} alt='no records'/>
                        <Text as='h5' fontSize='18px' fontWeight='500' my='20px' textAlign='center' >No records found!</Text>
                        <Text>You can crawl Urls by clicking on the Crawl URL button</Text>
                      </Flex>
                    </Td>
                  </Tr>
              }
            </Tbody>
          </Table>
        </TableContainer>
        <Box display='flex' justifyContent='space-between' width='100%' my='5' >
      <Box display='flex' alignItems='center'>
          Show
          <Box border='1px solid #e2e8f0' borderRadius='5px' mx='3'  >
          <select onChange={(e)=>updateItemsPerPage(e.target.value)} style={{borderRadius:'5px',height:'30px'}} >
              <option value='100'>100</option>
              <option value='200' >200</option>
          </select>
          </Box>files per page</Box>
        <Pagination totalPages={pagesLength} currentPage={globalCrawlState.currentPage} onPageChange={updateCurrentPage} />
      </Box>
      </React.Fragment>
    )
  }
  
  export default CrawlTable