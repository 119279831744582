import React from 'react'
import {HStack,Stack,Flex,Heading,Divider,Button,Box,Drawer,DrawerOverlay,DrawerContent,DrawerBody,Text,Spinner} from '@chakra-ui/react'
import { useState,useRef } from 'react'
import { FiUpload } from 'react-icons/fi'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import ActionResult from './ActionResult'
import CrawlUrl from './CrawlUrl'
import CrawlUrlList from './CrawlUrlList'
import DeleteModal from '../../commonComponents/DeleteModal'
import CrawlTable from './CrawlTable'
import Search from 'app/commonComponents/utils/Search'
import { useServicesContext } from "services/apiServices"
import { useCrawlContext } from './context/CrawlContext'
import  ErrorPage from 'app/commonComponents/ErrorPage'

const CrawlContainer = () => {
    const btnRef = useRef()
    const { globalCrawlState, handleBulkDeleteOpen, closeDeleteModal, onAddUrlSuccess, openDrawer, closeDrawer, onAddUrlFailure, onEditUrlSuccess, onEditUrlFailure } = useCrawlContext()
    const {globalState, disabledSources} = useServicesContext()
    const [searchByName, setSearchByName] = useState('')

  const isEnabled = !disabledSources.includes('Crawl URL')
  
    const enableBulkDeleteUrlButton = () =>{
        return globalCrawlState.urls.some(item=>(globalCrawlState.checkedUrls.includes(item.id)))
     }
    const drawerElement = globalCrawlState.drawerState.isOpen && (globalCrawlState.drawerState.page==='crawlForm'||globalCrawlState.drawerState.page==='editUrl')? <CrawlUrl closeDrawer={closeDrawer} page={globalCrawlState.drawerState.page} totalUrls={globalState.totalRecords.filter(file=>file.docType==='url'&& file.crawlStatus==='Published')} onAddUrlSuccess={onAddUrlSuccess} onAddUrlFailure={onAddUrlFailure} onEditUrlSuccess={onEditUrlSuccess} onEditUrlFailure={onEditUrlFailure} urlToEdit={globalCrawlState.urls.find(url=>url.id===globalCrawlState.selectedUrl)}/>
                    : globalCrawlState.drawerState.isOpen && globalCrawlState.drawerState.page==='previewUrl' ? <CrawlUrlList/> 
                    : globalCrawlState.drawerState.isOpen && (globalCrawlState.drawerState.page==='success' || globalCrawlState.drawerState.page==='fail') ? <ActionResult />
                    : globalCrawlState.drawerState.isOpen ?
                    <Box height='80vh' width='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column' >
                      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl'/>
                      <Text my='2'></Text>
                    </Box>
                    :<></>

  return (
    <>
      <Stack  borderRadius="10px" pos="relative" color='#617182'>
        <Flex justify="space-between" alignItems="center">
          <Heading as="h6" fontSize="18px" fontWeight='500' color='#000'>Crawled URLs</Heading> 
          <HStack>
            {isEnabled && <Search search={searchByName} setSearch={setSearchByName} searchPlaceholder={'Search by Url'} />}
            {enableBulkDeleteUrlButton() &&
              <Button leftIcon={<MdOutlineDeleteOutline size='24px'/>} minWidth='132px' h='36px' color='#E53E3E' bg='rgba(229, 62, 62, 0.10)' border-radius= '5px' fontSize='14px' onClick={handleBulkDeleteOpen}>Delete</Button>
            }
            <Box position="relative" ms='10px' display='flex' >
              {isEnabled && <Button minW="100px" rightIcon={<FiUpload size='24px' />} bg="#1e90ff" color="#fff" h='36px' fontSize='14px' onClick={openDrawer}>Crawl URL </Button>}
              <Drawer isOpen={globalCrawlState.drawerState.isOpen} placement="right" onClose={closeDrawer} finalFocusRef={btnRef} size="md"  >
                <DrawerOverlay />
                <DrawerContent minW='800px'>
                  <DrawerBody p='0' >
                    {drawerElement}
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </Box>
          </HStack>
        </Flex>
        <Divider />
        {isEnabled ? <CrawlTable searchByName={searchByName} />
          :
          <ErrorPage errorTitle='Access Forbidden' errorMessage={`Sorry, you don't have permission to access this page. If you believe this is an error, please contact your customer service manager.`} statusCode={403} buttonText='Go Back' />
        }
      </Stack>
      <DeleteModal isOpen={globalCrawlState.modalState.isOpen} closeModal={closeDeleteModal} callback={globalCrawlState.modalState.callBack} message={globalCrawlState.modalState.message}/>
  </>
  )
}
export default CrawlContainer