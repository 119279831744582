import React from 'react'
import {
  Divider,Button,Tabs,TabList,TabPanels,Tab,TabPanel,
  Flex,Stack,Box,InputGroup,InputRightElement,Input,Image,Text, UnorderedList, ListItem,Spinner,IconButton,Tooltip, useToast,Progress
} from "@chakra-ui/react"
import {HiOutlineArrowCircleLeft} from 'react-icons/hi'
import { MdOutlineArrowForward } from "react-icons/md"

import {useKnowledgeGapsContext} from '../context/KnowledgeGapsContext'

const AddNewKnowledgePage = () => {
  const {openDrawer} = useKnowledgeGapsContext()
  return (
    <Stack>
        <Flex direction='column' gap='20px' p='20px'>
            <Flex gap='4px' alignItems='flex-start'>
                <HiOutlineArrowCircleLeft cursor='pointer' size='42px' color='#4299E1' onClick={()=>openDrawer({page:'home'})} data-testid='kg_drawer_add_knowledge_back_icon'/>
                <Flex direction='column' gap='6px' alignItems='flex-start'>
                    <Text as='span' fontSize="16px" fontWeight='bolder'  data-testid='kg_drawer_home_heading'>Add new knowledge</Text>
                    <Text as='span' fontSize="14px" color='#6B7280' data-testid='kg_drawer_home_text'>The system has identified several matching knowledge sources in the existing knowledge base. You can either merge with the existing knowledge source or create as new.</Text>
                    <Flex direction='column' gap={10} mt={10}>
                        <Flex gap='11px' alignItems='center'>
                            <MdOutlineArrowForward size='14px' color='#2563EB'/>
                            <Button variant='link' color="#2563EB" fontSize='14px'onClick={()=>openDrawer({page:'createArticle'})} data-testid='kg_drawer_add_knowledge_add_article_button'>Add a knowledge article</Button>
                        </Flex>
                        <Flex gap='11px' alignItems='center'>
                            <MdOutlineArrowForward size='14px' color='#2563EB'/>
                            <Button variant='link' color="#2563EB" fontSize='14px' onClick={()=>openDrawer({page:'crawlUrl'})} data-testid='kg_drawer_add_knowledgee_add_url_button' >Crawl a URL</Button>
                        </Flex>
                        <Flex gap='11px' alignItems='center'>
                            <MdOutlineArrowForward size='14px' color='#2563EB'/>
                            <Button variant='link' color="#2563EB" fontSize='14px' onClick={()=>openDrawer({page:'createDocument'})} data-testid='kg_drawer_add_knowledge_add_document_button' >Add a document</Button>
                        </Flex>
                    </Flex>
                    <Button mt={40} alignSelf='flex-end' bg="transparent" padding='0px 28px' height='36px' variant='outline' color="#6B7280"  fontSize='14px' onClick={()=>openDrawer({page:'home'})} data-testid='kg_drawer_add_knowledge_cancel_button'>Cancel</Button>
               </Flex>
          </Flex>
        </Flex>
    </Stack>
  )
}

export default AddNewKnowledgePage 