import { useState, createContext, useContext,useEffect} from 'react'
import {useToast} from "@chakra-ui/react"
import { useMutation, useQueryClient, useQuery } from 'react-query'
import {useServicesContext } from "services/apiServices"
const KnowledgeArticlesContext = createContext(null)

export function KnowledgeArticlesProvider({children}) {

    const {apiCall,updateArticles,globalState:commonGlobalState,triggerRefetchTotalRecords} = useServicesContext()

    const [globalState, setGlobalState] = useState(initialState)
    const queryClient = useQueryClient()
    const toast = useToast()

    const transformDate = date => {
        const utcDateString = date
        const utcDate = new Date(utcDateString)
        const localDate = new Date(utcDate.getTime())
        const options = {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        }
        return localDate.toLocaleString('en-US', options)
    }
    const getArticles = () => apiCall({
        options: {
          url: `InputFiles/getFiles`,
          method: "GET",
          query:`$filter=(docType eq 'article' and status eq 'Published')&$top=1000000&$orderby=updatedAt desc`
          },
      })
  
    const { isFetching, data,} = useQuery(['articles'], ()=>getArticles(),{enabled:globalState.fetchArticles})
  
    useEffect(()=>{
        if(data){
          const fileTypesFilter = data.data.rows.map(item=>({...item,createdAt:transformDate(item.createdAt),updatedAt:transformDate(item.updatedAt)}))
          .filter((item)=>{
            return item.articleData !== null || undefined
          })
            setGlobalState(prevState => ({...prevState,articles:fileTypesFilter,fetchArticles:false}))
            updateArticles()
        }
      },[data])

      useEffect(()=>{
        setGlobalState(prevState=>({...prevState,isFetching}))
      },[isFetching])

    useEffect(()=>{
         setGlobalState(prevState=> ({...prevState,fetchArticles:commonGlobalState.refetchArticles}))
      },[commonGlobalState.refetchArticles])
    
    useEffect(()=>{
    const index = globalState.articles.find((article)=>article.articleData.id===commonGlobalState.articleTabState.selectedArticle)?.id
    if(index)
        setGlobalState(prevState=>({...prevState,page:'detail',selectedArticle:index}))
    },[commonGlobalState.articleTabState])

    const mutation = useMutation(
        apiCall,
        {
            onSuccess:(data,variables)=>{
                queryClient.invalidateQueries('articles')
                onCreateEditSuccess(variables)
                 
            },
            onError: (err,variables) => {
                toast({
                    title: 'Error',
                    description: variables.payload.operation==='create_KnowledgeArticle'?'Creation of Knowledge Article failed': variables.payload.operation==='update_KnowledgeArticle'? 'Knowledge Article could not be updated':'Knowledge Article could not be deleted',
                    status: 'error',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                }) 
                onCreateEditfailure()
            }
        }
    )

    const callKnowledgeArticlePostAPI = async (urlBody,headers={}) => {
        mutation.mutate({
            options: {
            url: `InputFiles/knowledgeArticles`,
            method: "POST",
            headers
            },
            payload: urlBody,
        })
    }
    //Backend API call to delete one or more KnowledgeArticles sites
    const deleteKnowledgeArticles= async ()=>{
        const urlBody ={operation:'delete_KnowledgeArticles',payload:globalState.deleteModalState.itemToDelete}
        callKnowledgeArticlePostAPI(urlBody)
        closeDeleteModal()
        triggerRefetchTotalRecords()
    }

    const openListPage = () => {
        setGlobalState(prevState => ({...prevState,page:'list'}))
    }

    const openCreatePage = () => {
        setGlobalState(prevState => ({...prevState,page:'create',selectedArticle:null}))
    }

    const openEditPage = (id) => {
        setGlobalState(prevState => ({...prevState,page:'edit',selectedArticle:id?id:prevState.selectedArticle}))
    }
    
    const openDetailPage = (id) => {
        setGlobalState(prevState => ({...prevState,page:'detail',selectedArticle:id?id:prevState.selectedArticle}))
    }

    const refetchArticles = ()=>{
        setGlobalState(prevState => ({...prevState,fetchArticles:true}))
    }

    const updateArticle = (article) => {
        const updatedArticles = [...globalState.articles]
        const index = globalState.articles.findIndex(item=> item.id===article.id)
        updatedArticles[index] = article
        setGlobalState(prevState => ({...prevState,articles:updatedArticles}))
    }
    //Handle Open Modal
    const openDeleteModal = (dbId,articleUUId,callPinecone) =>{
        setGlobalState(prevState=> ({...prevState, deleteModalState:{isOpen:true,itemToDelete:{dbId,articleUUId,callPinecone}}}))
    }

    const closeDeleteModal = () =>{
        setGlobalState(prevState=> ({...prevState, deleteModalState:initialDeleteModalState }))
    }

    const openPreviewModal = (previewData) =>{
        setGlobalState(prevState=> ({...prevState, previewModalState:{isOpen:true,previewData} }))
    }

    const closePreviewModal = () =>{
        setGlobalState(prevState=> ({...prevState, previewModalState:initialPreviewModalState }))
    }

    const handleBulkDelete = () =>{
        // const itemsToDelete = []
        // checkedFiles.forEach(file => itemsToDelete.push({docName:entries.find(entry=>entry.id===file).docName,id:file}))
        // openModal({
        //     title:'Confirm Delete KnowledgeArticles Site',
        //     message:`Are you sure want to delete ${checkedFiles.length} site${checkedFiles.length>1?'s':''}?`,
        //     callbackFunction:deleteKnowledgeArticles,
        //     callbackArgs:{itemsToDelete:itemsToDelete}
        // })
    }

    const updateListPageProps = (props)=>{
        setGlobalState(prevState => ({...prevState,listPageProps:{...prevState.listPageProps,...props}}))
    }
    const resetListPageProps = ()=>{
        setGlobalState(prevState => ({...prevState,listPageProps:initialListPageProps}))
    }
    const updateCreateEditPageProps = (page)=>{
        setGlobalState(prevState => ({...prevState,createEditPageProps:{prevPage:page}}))
    }
    const updateDetailPageProps = (props)=>{
        setGlobalState(prevState => ({...prevState,detailPageProps:{...prevState.detailPageProps,...props}}))
    }

    const onCreateEditSuccess = (variables) => {
        triggerRefetchTotalRecords()
        toast({
            title: 'Success',
            description: variables.payload.operation==='create_KnowledgeArticle'?'Knowledge Article created successfully': variables.payload.operation==='update_KnowledgeArticle'? 'Knowledge Article updated successfully':'Knowledge Article deleted successfully',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          })
        setGlobalState(prevState=> ({...prevState,fetchArticles:true}))
        if(globalState.page === 'create')
            resetListPageProps()
        else
            updateListPageProps({fetchArticles:true})

        if(globalState.page==='edit' && globalState.createEditPageProps.prevPage==='detail'){
            updateDetailPageProps({fetchArticle:true})
            openDetailPage() 
        }  
        else
            openListPage()      
    }

    const onCreateEditfailure = () =>  {
        if(globalState.createEditPageProps.prevPage==='list')
            openListPage()
        else
            openDetailPage()
    }

    return (
        <KnowledgeArticlesContext.Provider value={{globalState,callKnowledgeArticlePostAPI,deleteKnowledgeArticles,openListPage,openCreatePage,openEditPage,openDetailPage,refetchArticles,updateArticle,openDeleteModal,closeDeleteModal,openPreviewModal,closePreviewModal,updateListPageProps,updateCreateEditPageProps,resetListPageProps,updateDetailPageProps,onCreateEditSuccess,onCreateEditfailure}}>
            {children}     
        </KnowledgeArticlesContext.Provider>
    )
}

export function useKnowledgeArticlesContext() {
  return useContext(KnowledgeArticlesContext)
}

const initialDeleteModalState = {
    isOpen:false,
    itemToDelete:{},
}
const initialPreviewModalState = {
    isOpen:false,
    previewData:{
        title:'',
        content:'',
        scripted:false,
        audience:[]
    }
}

const initialListPageProps = {
    currentPage:1,
    itemsPerPage:25,
    searchQuery:'',
    filterRecords:[{label:'All Articles',value:'all'}]
}

const initialState = {
    isFetching:false,
    fetchArticles:true,
    articles:[],
    listPageProps: initialListPageProps,
    detailPageProps: {fetchArticle:false},
    createEditPageProps : {prevPage:'list'},
    page:'list',
    selectedArticle:null,
    openKnowledgeArticlesModal:false,
    deleteModalState:initialDeleteModalState,
    previewModalState:initialPreviewModalState
}