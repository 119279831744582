import { Input, InputGroup,InputRightElement } from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import React from 'react'

const Search = ({search,setSearch,searchPlaceholder}) => {
    const handleSearch=(e)=>{
        setSearch(e.target.value)
    }
  return (
    <InputGroup>
        <Input h='36px' fontSize='14px' placeholder={searchPlaceholder} value={search} onChange={handleSearch} />
        <InputRightElement pointerEvents="none" children={<FiSearch />} />
    </InputGroup>
  )
}

export default Search